<template>
  <div class="user-tab-overview">
    <div class="justify-end d-flex mb-3 mr-3">
      <v-chip
        color="success"
        @click="$emit('openRewardAside')"
      >
        <v-icon
          size="20"
          class="mr-1"
        >
          {{ icons.mdiPlus }}
        </v-icon> Add
      </v-chip>
    </div>
    <v-card
      class="mb-7"
      :loading="loading"
    >
      <v-data-table
        :headers="tableColumnHeaders"
        :items="distributorReward.records"
        hide-default-footer
        disable-pagination
      >
        <template #[`item.started_at`]="{item}">
          <span class="text-no-wrap">{{ dayjs(item.started_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
        <template #[`item.ended_at`]="{item}">
          <span class="text-no-wrap">{{ item.ended_at ? dayjs(item.ended_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
        </template>
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="$emit('openEditRewardAside',item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteReward(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useConfirm } from '@/composables'
import router from '@/router'
import { destroyReward } from '@api/distributor/distributor'
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  components: {},
  props: {
    distributorReward: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    props.distributorReward

    onMounted(async () => {})

    const tableColumnHeaders = [
      {
        text: 'Type',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Name',
        value: 'name.en',
        sortable: false,
      },
      {
        text: 'Max Rewards',
        value: 'max_rewards',
        sortable: false,
      },
      {
        text: 'Reward Points',
        value: 'reward_points',
        sortable: false,
      },
      {
        text: 'Started At',
        value: 'started_at',
        sortable: false,
      },
      {
        text: 'Ended At',
        value: 'ended_at',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ]

    const distributor_id = router.currentRoute.params.id
    const loading = ref(false)
    const errors = ref([])

    const deleteReward = async form => {
      if (useConfirm('confirm to remove this reward campaign?')) {
        loading.value = true
        const request = destroyReward(distributor_id, form.id)
        await request
          .then(res => {
            location.reload()
          })
          .catch(err => {
            errors.value = useExtractErrors(err)
          })
        loading.value = false
      }
    }

    return {
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
      },
      dayjs,
      deleteReward,
      loading,
      errors,
    }
  },
}
</script>
